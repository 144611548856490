import React from "react"
import resolveLink from "~utils/resolveLink"
import { Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Section from "./Section"
import Image from "./Image"
import { css } from "@emotion/react"
import CenteredSlider from "./CenteredSlider"
import { mobile, tablet } from "~styles/global"
import styled from "@emotion/styled"
import ProductGrid from '~components/ProductGrid'
import HeroAndProducts from '~components/HeroAndProducts'

const serializers = ({ wideQA }) => ({
  marks: {
    caps: props => {
     return (
      <span className="caps">
        {props.children}
      </span>
     ) 
    },
    internalLink: props => {
      return <Link to={resolveLink(props.mark.page)} /*className="highlight-inline"*/ >{props.children}</Link>
    },
    link: props => {
      return (
        <a 
          href={props.mark?.href} 
          target={props.mark.blank ? '_blank' : '_self'} 
          rel='noreferrer'
          // className="highlight-inline"
        >
          {props.children}
        </a>
      )
    }
  },
  types: {
    imageWithCaptionModule: props => {
      return (
        <Section css={css`margin: 100px 0;${mobile}{margin: 38px 0 42px;}`}>
          <div 
            css={css`
              grid-column: ${props.node.width ===`fullWidth` ? `1/13` : `2/12`};
              ${mobile}{
                grid-column: span 12;
              }
          `}> 
            <div css={css`
              margin: 0 ${props.node.width ===`fullWidth` ? `-40px` : `0`};
              ${mobile}{
                margin: 0 ${props.node.width ===`fullWidth` ? `-15px` : `0`};
              }; 
            `}>
            <Image 
              image={props.node?.image} 
              css={css`
              margin-bottom: 12px; 
              ${mobile}{
                display: none;
              }
              `}
            />
            <Image 
              image={props.node.width !==`fullWidth` ? props.node?.image : props.node?.mobileImage ?? props.node?.image}
              css={css`
              margin-bottom: 12px; 
              display: none;
              ${mobile}{
                display: block;
              }
              `}
            />
            </div>
          </div>
          <div className="highlight-s" css={css`grid-column: 2/12;${mobile}{grid-column: span 12;}`}>
						<div 
							css={css`
								${mobile}{
									display: none;
								}
							`}
						>
							<RichText 
								content={props.node?.image?.caption} 
							/>
						</div>
						<div
							css={css`
								display: none;
								${mobile}{	
									display: block;
								}
							`}
						>
							<RichText 
								content={props.node?.mobileImage?.caption ?? props.node?.image?.caption} 
							/>
						</div>
          </div>
        </Section>
      ) 
    },
    productRow: props => {
      return (
				<>
					<CenteredSlider 
						productSlider 
						slides={props.node?.products} 
						title={props.node?.title}
						css={css`margin: 90px 0;${mobile}{margin: 30px 0}`}
					/>
				</>
      ) 
    },
		productGrid: props => {
      return (
        <ProductGrid 
					productsPerRow={props.node?.productsPerRow}
					products={props.node?.products} 
          title={props.node?.title}
          css={css`margin: 90px 0;${mobile}{margin: 30px 0}`}
        />
      ) 
    },
    line: props => {
      return (
        <Section css={css`margin: 100px 0; ${mobile}{margin: 39px 0;}`}>
          <div css={css`height: 1px; grid-column: span 12; background-color: var(--red);`}></div>
        </Section>
      ) 
    },
    doubleImageWithCaption: props => {
      return (
        <Section css={css`
          margin: ${props.node?.stagger ? `110px 0;` : `100px 0;`} 
          ${mobile}{
            margin: 44px 0;
          }
        `}>
          <Image 
            image={props.node?.imageLeft}
            aspectRatio={props.node?.imageLeft?.asset?.metadata?.dimensions?.aspectRatio} 
            css={css`
              grid-column: 2/7; 
              transform: ${props.node?.stagger ? `translateY(-17px)` : `none`};
              ${mobile}{
                grid-column: 1/7;
                transform: ${props.node?.stagger ? `translateY(-6px)` : `none`};
            }`}
          />
          <Image 
            image={props.node?.imageRight} 
            aspectRatio={props.node?.imageLeft?.asset?.metadata?.dimensions?.aspectRatio} 
            css={css`
              grid-column: 7/12; 
              transform: ${props.node?.stagger ? `translateY(17px)` : `none`};
              ${mobile}{
                grid-column: 7/13;
                transform: ${props.node?.stagger ? `translateY(6px)` : `none`};
            }`}
          />
          {props.node?.caption && 
            <div 
              css={css`
                grid-column: 2/12; 
                padding-top: ${props.node?.stagger ? `22px`:`12px`};
                ${mobile}{
                  grid-column: 1/13; 
                  padding-top: 10px;
              }`} 
              className="highlight-s"
            >
              <RichText content={props.node?.caption}/>
            </div>
          }
        </Section>
      ) 
    },
    imageText: props => {
      return (
        <Section css={css`margin: 100px 0; ${mobile}{margin: 40px 0;}`}>
					<div 
						css={css`
							grid-column: ${props.node?.reverse ? `7/12` : `2/7`}; 
							${mobile}{
								grid-column: span 12; 
								margin-bottom: 43px;
							}
						`}
					>
						<Image 
							image={props.node?.image}
						/>
							<div 
								css={css`
									padding-top: 12px;
									${mobile}{
										padding-top: 10px;
								}`} 
								className="highlight-s"
							>
              <RichText content={props.node?.image?.caption}/>
            </div>
					</div>
          <DropCap
           css={css`
            grid-column: ${props.node?.reverse ? `2/7` : `7/12`}; 
            grid-row: 1;
            ${mobile}{
              grid-column: span 12;
              grid-row: 2;
            }
          `}>
            <RichText content={props.node?.text} />
          </DropCap>
        </Section>
      )
    },
    qAndA: props => {
      return (
        <Section className="q-and-a" css={css`
					margin: ${wideQA ? '0' : '50px 0'};
					${mobile}{
						margin: ${wideQA ? '0' : '40px 0'};
					}
					> div{
						padding: ${wideQA ? '0' : '0 40px'};
						${mobile}{
							padding: ${wideQA ? '0' : '0 15px'};
						}
					}
					`}>
          <div css={css`grid-column: ${wideQA ? 'span 12' : '4/10'}; ${mobile}{grid-column: span 12;}`}>
            <Question>
							{props.node?.qName &&
								<QName className="highlight">{props.node?.qName}:</QName>
							}
              <span className="highlight-l" css={css``}>{props.node?.question}</span>
            </Question>
            <QBorder/>
						{props.node?.aName &&
							<AName className="highlight-inline">
								{props.node?.aName}:
							</AName>
						}
						<Answer>
            <RichText content={props.node?.answer}/>
						</Answer>
          </div>
        </Section>
      )
    },
    imageQAndA: props => {
      return (
        <Section css={css`margin: 100px 0; ${mobile}{margin: 40px 0;}`}>
					<div
						css={css`
							grid-column: ${props.node?.reverse ? `7/12` : `2/7`}; 
							${mobile}{
								grid-column: span 12; 
								margin-bottom: 43px;
							}
						`}
					>
						<Image
							image={props.node?.image}
						/>
						<div 
							css={css`
								padding-top: 12px;
								${mobile}{
									padding-top: 10px;
							}`} 
							className="highlight-s"
						>
              <RichText content={props.node?.image?.caption}/>
            </div>
					</div>
          <div css={css`
						grid-column: ${props.node?.reverse ? `2/7` : `7/12`}; 
						grid-row: 1;
						align-self: start;
						position: sticky;
						top: 80px;
						${mobile}{
							grid-column: span 12;
							grid-row: 2;
						}`}>
            {props.node?.questions.map((question, i) => (
              <div 
              key={question._key} 
              css={css`
                margin-bottom: ${props.node?.questions?.length === i + 1 ? `0px` : `50px`};
                ${mobile}{
                  margin-bottom: ${props.node?.questions?.length === i + 1 ? `0px` : `40px`};
                }
              `}>
                <Question>
                 {question.qName && <QName className="highlight">{question.qName}:</QName>}
                  <span className="highlight-l">{question.question}</span>
                </Question>
                <QBorder/>
                {question.aName && <AName className="highlight-inline">
                  {question.aName}:
                </AName>}
                <RichText content={question.answer}/>
              </div>
            ))}
          </div>
        </Section>
      )
    },
    quoteModule: props => {
      return (
        <Section css={css`margin: 100px 0; ${mobile}{margin: 40px 0;}`}>
          <div css={css`text-align: center; grid-column: 3/11;${mobile}{grid-column: span 12; margin: 0 20px; >h2{ margin-bottom: 14px;}}`}>
						<h2>"{props.node?.quote}"</h2>
						{props.node?.attribution &&
							<h6 className='caps' css={css`margin-top: 30px; ${mobile}{margin-top: 14px;}`}>
								{props.node?.attribution}
							</h6>
						}
          </div>

        </Section>
      )
    },
		heroAndProducts: props => {
			return (
				<HeroAndProducts 
					products={props.node?.products}
					hero={props.node?.hero} 
					title={props.node?.title}
					reverse={props.node?.reverse}
				/>
				)
		}
  }
})

const Question = styled.div`
  margin-bottom: 8px;
`

const QName = styled.span`
  text-transform: uppercase;
  display: inline-block; 
  margin-right: 10px;
`
const QBorder = styled.div`
  border-bottom: 1px solid var(--black); 
  margin-bottom: 14px;
`
const AName = styled.span`
  float: left; 
  margin-right: 10px; 
  top: 1px; 
  ${mobile}{
    top: 0px;
  }
`
const Answer = styled.div`
	margin-bottom: 30px;
	${mobile}{
		margin-bottom: 22px;
	}
`
const DropCap = styled.div`
	align-self: start;
	position: sticky;
	top: 80px;
  p{
    :first-child {
      :first-letter{
        float: left;
        font-size: 62px;
        line-height: 0.8;
        padding-top: 6px;
        padding-right: 4px;
        margin-left: 0px;
        ${tablet}{
          padding-right: 2px;
          padding-top: 4px;
          font-size: 47px;
          line-height: 0.8;
          margin-left: -1px;
        }
      }
    }
  }
`



const RichText = ({ content, wideQA }) => {
  return(
    <BlockContent blocks={content} serializers={serializers({wideQA})} ignoreUnknownTypes={true}></BlockContent>
  )
}

export default RichText
